var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationProvider',{staticClass:"relative w-full appearance-none",attrs:{"tag":"div","vid":_vm.vid,"rules":_vm.rules,"name":_vm.name || _vm.label},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var required = ref.required;
var ariaInput = ref.ariaInput;
var ariaMsg = ref.ariaMsg;
return [_c('label',{staticClass:"block w-full pb-1 font-semibold leading-normal text-14px",class:{
      'text-gray-700': !errors[0],
      'text-red-600': errors[0] || _vm.phone_error != '',
    },attrs:{"for":_vm.name},on:{"click":function($event){return _vm.$refs.input.focus()}}},[(_vm.label)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.label))]),_c('span',[_vm._v(_vm._s(required ? ' *' : ''))])]):_vm._e()]),(_vm.input_type == 'phone')?_c('vue-tel-input',{ref:"telInput",class:{
      'border-gray-400 placeholder-gray-500 telinput':
        !errors[0] || _vm.phone_error == '',
      'border-red-600 placeholder-red-400 custom-phone': errors[0],
      'has-value telinput': _vm.hasValue,
      'bg-oLightestGray text-oLightGray placeholder-oLightGray telinput': _vm.disabled,
    },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled},on:{"input":_vm.onChangePhoneNumber},model:{value:(_vm.innerValue),callback:function ($$v) {_vm.innerValue=$$v},expression:"innerValue"}}):_vm._e(),_c('div',{staticClass:"relative"},[(_vm.input_type == 'textarea')?_c('textarea',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"block w-full h-20 px-4 py-1 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'border-gray-400 placeholder-gray-500': !errors[0],
        'border-red-600 placeholder-red-400': errors[0],
        'has-value': _vm.hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
      },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}},'textarea',ariaInput,false)):_vm._e(),((_vm.type)==='checkbox'&&(_vm.input_type != 'phone' && _vm.input_type != 'textarea'))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'border-gray-400 placeholder-gray-500': !errors[0] || !_vm.errorMessage,
        'border-red-600 placeholder-red-400': errors[0] || _vm.errorMessage,
        'has-value': _vm.hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
      },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.innerValue)?_vm._i(_vm.innerValue,null)>-1:(_vm.innerValue)},on:{"change":function($event){var $$a=_vm.innerValue,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.innerValue=$$a.concat([$$v]))}else{$$i>-1&&(_vm.innerValue=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.innerValue=$$c}}}},'input',ariaInput,false)):((_vm.type)==='radio'&&(_vm.input_type != 'phone' && _vm.input_type != 'textarea'))?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'border-gray-400 placeholder-gray-500': !errors[0] || !_vm.errorMessage,
        'border-red-600 placeholder-red-400': errors[0] || _vm.errorMessage,
        'has-value': _vm.hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
      },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"type":"radio"},domProps:{"checked":_vm._q(_vm.innerValue,null)},on:{"change":function($event){_vm.innerValue=null}}},'input',ariaInput,false)):(_vm.input_type != 'phone' && _vm.input_type != 'textarea')?_c('input',_vm._b({directives:[{name:"model",rawName:"v-model",value:(_vm.innerValue),expression:"innerValue"}],ref:"input",staticClass:"block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500",class:{
        'border-gray-400 placeholder-gray-500': !errors[0] || !_vm.errorMessage,
        'border-red-600 placeholder-red-400': errors[0] || _vm.errorMessage,
        'has-value': _vm.hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray': _vm.disabled,
      },attrs:{"placeholder":_vm.placeholder,"disabled":_vm.disabled,"step":_vm.step,"min":_vm.min,"type":_vm.type},domProps:{"value":(_vm.innerValue)},on:{"input":function($event){if($event.target.composing){ return; }_vm.innerValue=$event.target.value}}},'input',ariaInput,false)):_vm._e(),(_vm.input_type == 'password')?_c('div',{staticClass:"eye-body",on:{"click":_vm.onChangeShowPassword}},[(_vm.show_password)?_c('i',{staticClass:"far fa-eye"}):_vm._e(),(!_vm.show_password)?_c('i',{staticClass:"far fa-eye-slash"}):_vm._e()]):_vm._e()]),(
      _vm.phone_error === '' &&
        _vm.errorMessage == '' &&
        (errors[0] ? true : !_vm.showSubText)
    )?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',_vm._b({directives:[{name:"show",rawName:"v-show",value:(errors[0]),expression:"errors[0]"}],staticClass:"block text-xs text-red-600 "},'span',ariaMsg,false),[_vm._v(_vm._s(errors[0]))])]):_vm._e(),(_vm.errorMessage == '' && _vm.showSubText)?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',{staticClass:"block text-xs text-gray-600 "},[_vm._v(_vm._s(_vm.subText))])]):_vm._e(),(_vm.phone_error != '')?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',_vm._b({staticClass:"block text-xs text-red-600 "},'span',ariaMsg,false),[_vm._v(_vm._s(_vm.phone_error))])]):_vm._e(),(_vm.errorMessage != '')?_c('div',{staticClass:"flex items-center min-h-6"},[_c('span',_vm._b({staticClass:"block text-xs text-red-600 "},'span',ariaMsg,false),[_vm._v(_vm._s(_vm.errorMessage))])]):_vm._e()]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }