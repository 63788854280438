<template>
  <ValidationProvider
    class="relative w-full appearance-none"
    tag="div"
    :vid="vid"
    :rules="rules"
    :name="name || label"
    v-slot="{ errors, required, ariaInput, ariaMsg }"
  >
    <label
      class="block w-full pb-1 font-semibold leading-normal text-14px"
      @click="$refs.input.focus()"
      :for="name"
      :class="{
        'text-gray-700': !errors[0],
        'text-red-600': errors[0] || phone_error != '',
      }"
    >
      <div v-if="label">
        <span>{{ label }}</span>
        <span>{{ required ? ' *' : '' }}</span>
      </div>
    </label>
    <vue-tel-input
      v-model="innerValue"
      v-if="input_type == 'phone'"
      ref="telInput"
      :placeholder="placeholder"
      @input="onChangePhoneNumber"
      :class="{
        'border-gray-400 placeholder-gray-500 telinput':
          !errors[0] || phone_error == '',
        'border-red-600 placeholder-red-400 custom-phone': errors[0],
        'has-value telinput': hasValue,
        'bg-oLightestGray text-oLightGray placeholder-oLightGray telinput': disabled,
      }"
      :disabled="disabled"
    >
    </vue-tel-input>

    <div class="relative">
      <textarea
        v-if="input_type == 'textarea'"
        class="block w-full h-20 px-4 py-1 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0],
          'border-red-600 placeholder-red-400': errors[0],
          'has-value': hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
        }"
        :placeholder="placeholder"
        :disabled="disabled"
        ref="input"
        v-model="innerValue"
        v-bind="ariaInput"
        :step="step"
      />

      <input
        v-if="input_type != 'phone' && input_type != 'textarea'"
        class="block w-full px-4 py-1.5 leading-normal bg-white border border-gray-400 rounded outline-none focus:ring-0 focus:border-gray-700 focus:placeholder-gray-500"
        :class="{
          'border-gray-400 placeholder-gray-500': !errors[0] || !errorMessage,
          'border-red-600 placeholder-red-400': errors[0] || errorMessage,
          'has-value': hasValue,
          'bg-oLightestGray text-oLightGray placeholder-oLightGray': disabled,
        }"
        :type="type"
        :placeholder="placeholder"
        :disabled="disabled"
        ref="input"
        v-model="innerValue"
        v-bind="ariaInput"
        :step="step"
        style=""
        :min="min"
      />

      <div
        class="eye-body"
        v-if="input_type == 'password'"
        @click="onChangeShowPassword"
      >
        <i class="far fa-eye" v-if="show_password"></i>
        <i class="far fa-eye-slash" v-if="!show_password"></i>
      </div>
    </div>

    <div
      class="flex items-center min-h-6"
      v-if="
        phone_error === '' &&
          errorMessage == '' &&
          (errors[0] ? true : !showSubText)
      "
    >
      <span
        class="block text-xs text-red-600 "
        v-bind="ariaMsg"
        v-show="errors[0]"
        >{{ errors[0] }}</span
      >
    </div>
    <div
      class="flex items-center min-h-6"
      v-if="errorMessage == '' && showSubText"
    >
      <span class="block text-xs text-gray-600 ">{{ subText }}</span>
    </div>
    <div class="flex items-center min-h-6" v-if="phone_error != ''">
      <span class="block text-xs text-red-600 " v-bind="ariaMsg">{{
        phone_error
      }}</span>
    </div>
    <div class="flex items-center min-h-6" v-if="errorMessage != ''">
      <span class="block text-xs text-red-600 " v-bind="ariaMsg">{{
        errorMessage
      }}</span>
    </div>
  </ValidationProvider>
</template>

<script>
// import { ValidationProvider } from "vee-validate";

export default {
  name: 'TextInput',
  components: {
    // ValidationProvider
  },
  props: {
    vid: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    input_type: {
      type: String,
      default: '',
    },
    rules: {
      type: [Object, String],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    show_password: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
      validator(value) {
        return [
          'url',
          'text',
          'password',
          'tel',
          'search',
          'number',
          'email',
          'date',
          'textarea',
        ].includes(value)
      },
    },
    step: {
      type: String,
      default: '1',
    },
    value: {
      type: null,
      default: '',
    },
    errorMessage: {
      type: String,
      default: '',
    },
    errorKey: {
      type: String,
      default: '',
    },
    min: {
      type: String,
      default: '',
    },
    subText: {
      type: String,
      default: '',
    },
    showSubText: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    innerValue: '',
    phone_error: '',
  }),
  computed: {
    hasValue() {
      return !!this.innerValue
    },
  },
  methods: {
    onChangePhoneNumber(number, valid) {
      if (valid.isValid) {
        //true
        this.innerValue = valid.number.input
        this.phone_error = ''
      } else {
        this.phone_error = 'The phone number field must be a valid'
      }
    },
    onChangeShowPassword() {
      this.$emit('showPassword', !this.show_password)
    },
  },
  watch: {
    innerValue(value) {
      this.$emit('input', value)
      this.$emit('clear-error', this.errorKey)
    },
    value(val) {
      if (val !== this.innerValue) {
        this.innerValue = val
      }
    },
  },
  created() {
    if (this.value !== null || typeof this.value !== 'undefined') {
      this.innerValue = this.value
    }
  },
}
</script>
<style lang="scss" scoped>
.min-h-6 {
  min-height: 1.5rem;
}
.eye-body {
  position: absolute;
  top: 0px;
  right: 0px;
  top: 8px;
  right: 11px;
  cursor: pointer;
  i {
    color: #00cb5d;
  }
}
</style>
